import * as MutationTypes from "../mutation_types";
import { callComplaintPublishedAPI, callQuestionnairePublishedAPI, callMailSendAPI, callDownloadTaskStatusAPI } from "../../api/task_statuses";
import { saveAs } from "file-saver";
const getDefaultState = () => {
    return {
        taskStatuses: {},
        complaintPublishedStausCount: 0,
        questionnairePublishedStatusCount: 0,
        mailSendStatusCount: 0
    };
};
const taskStatuses = {
    state: () => getDefaultState(),
    getters: {
        taskStatuses(state) {
            return state.taskStatuses;
        },
        complaintPublishedStausCount(state) {
            return state.complaintPublishedStausCount;
        },
        questionnairePublishedStatusCount(state) {
            return state.questionnairePublishedStatusCount;
        },
        mailSendStatusCount(state) {
            return state.mailSendStatusCount;
        }
    },
    actions: {
        fetchTaskStatuses({ dispatch, getters }) {
            return new Promise((resolve, reject) => {
                const targetMonth = getters.selectedTaskStatusDate;
                dispatch("resetTaskStatusesState");
                try {
                    const nestedTree = getters.corporationsNestedTree;
                    nestedTree.forEach((corporation, idx) => {
                        // APIを一度に呼び出さないよう0.5秒 インターバルを入れる
                        setTimeout(() => {
                            dispatch("fecthTaskStatus", { corporation, targetMonth });
                        }, 500 * idx);
                    });
                    resolve();
                }
                catch {
                    reject();
                }
            });
        },
        fecthTaskStatus({ dispatch, commit }, params) {
            const corporation = params.corporation;
            const corporationId = corporation.id;
            const targetMonth = params.targetMonth;
            commit(MutationTypes.CREATE_TASK_STATUS_OBJECT, corporationId);
            dispatch("fetchComplaintPublishedStatus", { corporationId, targetMonth });
            dispatch("fetchQuestionnairePublishedStatus", { corporationId, targetMonth });
            dispatch("fetchMailSendStatus", { corporationId, targetMonth });
            const children = corporation.children;
            children.forEach((child, idx) => {
                // APIを一度に呼び出さないよう0.5秒 インターバルを入れる
                setTimeout(() => {
                    dispatch("fecthTaskStatus", { corporation: child, targetMonth });
                }, 500 * idx);
            });
        },
        fetchComplaintPublishedStatus({ dispatch, commit, getters }, params) {
            return new Promise((resolve, reject) => {
                const corporationId = params.corporationId;
                callComplaintPublishedAPI(getters.authHeaders, {
                    corporationId: corporationId,
                    targetMonth: params.targetMonth
                }, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_COMPLAINT_PUBLISHED_STATUS, { status, corporationId });
                    dispatch("incrementComplaintPublishedStausCount");
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchQuestionnairePublishedStatus({ dispatch, commit, getters }, params) {
            return new Promise((resolve, reject) => {
                const corporationId = params.corporationId;
                callQuestionnairePublishedAPI(getters.authHeaders, {
                    corporationId: corporationId,
                    targetMonth: params.targetMonth
                }, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_QUESTIONNAIRE_PUBLISHED_STATUS, { status, corporationId });
                    dispatch("incrementQuestionnairePublishedStatusCount");
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        fetchMailSendStatus({ dispatch, commit, getters }, params) {
            return new Promise((resolve, reject) => {
                const corporationId = params.corporationId;
                callMailSendAPI(getters.authHeaders, {
                    corporationId: corporationId,
                    targetMonth: params.targetMonth
                }, 
                // Success
                (headers, status) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    commit(MutationTypes.ASSIGN_MAIL_SEND_STATUS, { status, corporationId });
                    dispatch("incrementMailSendStatusCount");
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        downloadTaskStatus({ commit, getters }) {
            return new Promise((resolve, reject) => {
                const targetMonth = getters.selectedTaskStatusDate;
                callDownloadTaskStatusAPI(getters.authHeaders, {
                    targetMonth: targetMonth
                }, 
                // Success
                (headers, csvData) => {
                    commit(MutationTypes.ASSIGN_AUTH_HEADERS, headers);
                    saveAs(csvData, `タスク進捗状況_${targetMonth.getFullYear()}年${targetMonth.getMonth() + 1}月.csv`);
                    resolve();
                }, 
                // Failure
                () => {
                    reject();
                });
            });
        },
        incrementComplaintPublishedStausCount({ commit, getters }) {
            const count = getters.complaintPublishedStausCount;
            commit(MutationTypes.ASSIGN_COMPLAINT_PUBLISHED_STAUS_COUNT, count + 1);
        },
        incrementQuestionnairePublishedStatusCount({ commit, getters }) {
            const count = getters.questionnairePublishedStatusCount;
            commit(MutationTypes.ASSIGN_QUESTIONNAIRE_PUBLISHED_STATUS_COUNT, count + 1);
        },
        incrementMailSendStatusCount({ commit, getters }) {
            const count = getters.mailSendStatusCount;
            commit(MutationTypes.ASSIGN_MAIL_SEND_STATUS_COUNT, count + 1);
        },
        resetTaskStatusesState({ commit }) {
            commit(MutationTypes.RESET_TASK_STATUSES_STATE);
        },
    },
    mutations: {
        [MutationTypes.ASSIGN_COMPLAINT_PUBLISHED_STATUS](state, result) {
            state.taskStatuses[result.corporationId].complaintPublishedStatus = result.status;
        },
        [MutationTypes.ASSIGN_QUESTIONNAIRE_PUBLISHED_STATUS](state, result) {
            state.taskStatuses[result.corporationId].questionnairePublishedStatus = result.status;
        },
        [MutationTypes.ASSIGN_MAIL_SEND_STATUS](state, result) {
            state.taskStatuses[result.corporationId].mailSendStatus = result.status;
        },
        [MutationTypes.CREATE_TASK_STATUS_OBJECT](state, corporationId) {
            state.taskStatuses[corporationId] = {};
        },
        [MutationTypes.ASSIGN_COMPLAINT_PUBLISHED_STAUS_COUNT](state, count) {
            state.complaintPublishedStausCount = count;
        },
        [MutationTypes.ASSIGN_QUESTIONNAIRE_PUBLISHED_STATUS_COUNT](state, count) {
            state.questionnairePublishedStatusCount = count;
        },
        [MutationTypes.ASSIGN_MAIL_SEND_STATUS_COUNT](state, count) {
            state.mailSendStatusCount = count;
        },
        [MutationTypes.RESET_TASK_STATUSES_STATE](state) {
            Object.assign(state, getDefaultState());
        },
    }
};
export default taskStatuses;
